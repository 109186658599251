<template>
    <div>
        <zw-table ref="table"
                  tableName="contact-persons"
                  base-table="contact-persons"
                  columnsPrefix="contact-persons.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :tbody-tr-class="rowClass"
                  :actions-list="getActions()"
        >
            <template #cell(actions)="row">
                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>
                    <b-dropdown-item v-if="$root.hasAccess('customers','update')"
                                     @click="openContactPerson(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$root.hasAccess('customers','delete')"
                                     @click="deleteContactPerson(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$root.hasAccess('customers','update') && row.item.active"
                                     @click="deactivateContactPerson(row.item.id)">
                        {{ $t('common.title.deactivate') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$root.hasAccess('customers','update') && !row.item.active"
                                     @click="activateContactPerson(row.item.id)">
                        {{ $t('common.title.activate') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'contact-persons',
    mixins: [commonTable, commonSave],
    props: {
        customerId: {
            type: Number,
            required: true
        },
    },
    mounted() {
        this.shown()
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPersonsTable']),
        shown() {
            this.loading = false
        },
        ctxAdditionalParams() {
            return {
                'customerId': this.customerId,
            }
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'ContactPerson/fetchContactPersons', 'getContactPersonsTable');
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.openContactPerson(null)
                    }
                }
            ]
            return actions
        },
        openContactPerson(contactPersonId) {
            this.$root.$children[0].openModal('contact-person-form', {
                id: contactPersonId,
                customerId: this.customerId
            })
        },
        deleteContactPerson(contactPersonId) {
            this.$removeConfirm('ContactPerson/deleteContactPerson', {
                contactPersonId,
                customerId: this.customerId
            })
        },
        deactivateContactPerson(contact) {
            contact.active = 0;
            this.$store.dispatch('ContactPerson/saveContactPerson', contact)
        },
        activateContactPerson(contact) {
            contact.active = 1;
            this.$store.dispatch('ContactPerson/saveContactPerson', contact)
        },
        rowClass(item) {
            if (item) {
                return item.default ? 'tr-warning' : ''
            }
            return '';
        },
    }
}
</script>