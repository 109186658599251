<template>
    <zw-sidebar @shown="shown">
        <template v-slot:sidebar-title>
            <b-row style="width: 100%">
                <b-col cols="10" style="font-size: 14px">
                    <span v-if="customerId">
                        ID: {{ customerId }}
                        <strong>{{ customerName }}</strong>
                    </span>
                    <span v-else>
                        {{ $t('common.form.title.createCustomer') }}
                    </span>
                    <span class="mr-2" v-if="customerData.email">
                        {{ customerData.email }}
                        <b-button variant="secondary"
                                  class="mr-2"
                                  size="sm"
                                  @click="$root.copyToClipboard(customerData.email)"
                        >
                            <font-awesome-icon icon="copy"/>
                        </b-button>

                        <b-button variant="warning"
                                  class="mr-2"
                                  size="sm"
                                  @click="$root.$children[0].openModal('email-modal', {documentId:customerId, docType:'customer_email'}, shown, {})"
                        >
                            <font-awesome-icon icon="envelope"/>
                        </b-button>
                    </span>
                    <span class="mr-2" v-if="customerData.mobile">
                        <a :href="'tel:'+customerData.mobile">{{ customerData.mobile }}</a>
                        <b-button variant="secondary"
                                  class="mr-2"
                                  size="sm"
                                  @click="$root.copyToClipboard(customerData.mobile)"
                        >
                            <font-awesome-icon icon="copy"/>
                        </b-button>

                        <b-button variant="secondary"
                                  class="mr-2"
                                  size="sm"
                                  @click="openWhatsApp(cleanPhone(customerData.mobile))"
                        >
                            <font-awesome-icon :icon="['fab', 'whatsapp']" class="text-success"/>
                        </b-button>
                    </span>

                    <b-button variant="primary"
                              class="mr-2"
                              size="sm"
                              @click="$root.$children[0].openModal('event-modal', {id: customerId}, shown, {width: '800px'})"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.event.button.create') }}
                    </b-button>

                    <b-button variant="primary"
                              class="mr-2"
                              size="sm"
                              @click="$root.$children[0].openModal('todo-modal', {customer_id:customerId}, shown)"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.add_todo') }}
                    </b-button>

                    <template v-if="customerData.statistics">
                        <b-button variant="info"
                                  class="mr-2"
                                  id="call-help"
                                  size="sm"
                        >
                            <font-awesome-icon class="mr-2"
                                               icon="phone-volume"
                            />
                            <span v-for="(action,key) in customerData.statistics.calls_actions" :key="key">
                                {{ action.count }}<span
                                v-if="key<Object.keys( customerData.statistics.calls_actions).length-1">/</span>
                            </span>
                            |
                            <span>
                                 {{ customerData.statistics.calls_without_actions }} / {{ customerData.statistics.calls_count }}
                            </span>
                        </b-button>

                        <b-popover target="call-help" triggers="hover focus">
                            <div style="min-width: 300px">
                                <b-row v-for="(action,key) in customerData.statistics.calls_actions" :key="key">
                                    <b-col class="text-right" cols="8">{{ action.name }}:</b-col>
                                    <b-col cols="4">{{ action.count }}</b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col class="text-right" cols="8">
                                        Without sub action:
                                    </b-col>
                                    <b-col cols="4">
                                        {{ customerData.statistics.calls_without_actions }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-right" cols="8">
                                        <strong>Total:</strong>
                                    </b-col>
                                    <b-col cols="4">
                                        {{ customerData.statistics.calls_count }}
                                    </b-col>
                                </b-row>
                            </div>
                        </b-popover>
                    </template>

                    <template v-if="customerData.statistics">
                        <b-button variant="info"
                                  class="mr-2"
                                  id="mail-help"
                                  size="sm"
                        >
                            <font-awesome-icon class="mr-2"
                                               icon="envelope-open"
                            />
                            {{ customerData.statistics.outgoing_mails }}/{{ customerData.statistics.opened_mails }} |
                            {{ customerData.statistics.received_mails }}
                        </b-button>

                        <b-popover target="mail-help" triggers="hover focus">
                            <div style="min-width: 250px">
                                <b-row>
                                    <b-col class="text-right" cols="8">
                                        Sent emails:
                                    </b-col>
                                    <b-col cols="4">
                                        {{ customerData.statistics.outgoing_mails }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-right" cols="8">
                                        Sent emails (opened):
                                    </b-col>
                                    <b-col cols="4">
                                        {{ customerData.statistics.opened_mails }}
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col class="text-right" cols="8">
                                        Received emails:
                                    </b-col>
                                    <b-col cols="4">
                                        {{ customerData.statistics.received_mails }}
                                    </b-col>
                                </b-row>
                            </div>
                        </b-popover>
                    </template>
                </b-col>
            </b-row>
        </template>
        <b-tabs v-if="!loading" lazy>
            <b-tab v-for="(tab,key) in tabs"
                   :active="activeTab==key"
                   :key="key"
                   @click="changeTab"
                   v-if="showTab(key)"
            >
                <template #title>
                    <font-awesome-icon :icon="icons[key]"></font-awesome-icon>
                    {{ $t('customer.tab.' + key) }}
                </template>
                <component v-bind:is="tab"
                           :customer-id="customerId"
                           :sidebar="$children[0].$refs['sidebar']"
                           v-on:updateCustomerId="updateCustomerId"
                ></component>
            </b-tab>
        </b-tabs>
    </zw-sidebar>
</template>

<script>
import ContactPersons from "@/tables/ContactPersons";
import Offerings from "@/tables/Offerings";
import CustomerForm from "@/modals/customer/CustomerForm";
import {mapGetters} from "vuex";

export default {
    name: 'customer-modal',
    components: {CustomerForm, Offerings, ContactPersons},
    data() {
        return {
            loading: true,
            activeTab: 'events',
            customerId: 0,
            payload: {},
            message: '',
            callback: null,
            tabs: {
                'events': () => import('@/modals/customer/CustomerEvents'),
                'general': () => import('@/modals/customer/CustomerForm'),
                'contact': () => import('@/modals/customer/CustomerContactForm'),
                'tax': () => import('@/modals/customer/CustomerTaxForm'),
                'social': () => import('@/modals/customer/CustomerSocialForm'),
                'conditions': () => import('@/modals/customer/CustomerConditionsForm'),
                'groups': () => import('@/modals/customer/CustomerGroupsForm'),
                'other': () => import('@/modals/customer/CustomerOtherForm'),
                'contact_persons': () => import('@/tables/ContactPersons'),
                'offerings': () => import('@/tables/Offerings'),
                'contracts': () => import('@/tables/Contracts'),
                'documents': () => import('@/tables/CustomerDocuments'),
                'clients': () => import('@/tables/Clients'),
                'emails': () => import('@/tables/CustomerMails'),
                'customer_templates': () => import('@/tables/CustomerTemplates.vue'),
            },
            icons: {
                'general': 'user',
                'contact': 'user',
                'tax': 'user',
                'social': 'user',
                'conditions': 'user',
                'groups': 'user',
                'other': 'user',
                'contact_persons': 'users',
                'offerings': 'list',
                'contracts': 'file-invoice',
                'documents': 'file',
                'clients': 'users',
                'emails': 'envelope',
                'events': 'calendar',
                'customer_templates': 'font',
            },
            customerName: '',
            customerData: {}
        }
    },
    methods: {
        ...mapGetters('Customer', ['getCustomer']),
        modalTitle() {
            return this.customerName
        },
        openWhatsApp(mobile) {
            window.open('https://api.whatsapp.com/send/?phone=' + mobile, '_blank');
        },
        cleanPhone(mobile) {
            let cleaned = '';
            if (mobile) {
                cleaned = mobile.replace(/[^\d+]/g, '');
                if (cleaned.startsWith('+')) {
                    cleaned = cleaned.substring(1);
                }
            }

            return cleaned;
        },
        shown() {
            this.customerId = this.payload.customerId

            if (this.customerId) {
                this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                    .then(() => {
                        let customer = JSON.parse(JSON.stringify(this.getCustomer()))
                        this.customerData = JSON.parse(JSON.stringify(this.getCustomer()))
                        this.customerName = [customer.company, customer.firstname, customer.lastname].join(' ').trim()
                    })
            }

            if (this.payload.tab) {
                this.activeTab = this.payload.tab
            }
            this.loading = false
        },
        updateCustomerId(reply) {
            this.customerId = reply;
        },
        changeTab() {
            this.$root.$children[0].updateFormValues('customer-modal')
        },
        showTab(key) {
            if (key == 'general') {
                return true;
            }
            if (this.$root.simpleMode) {
                return this.customerId && ['general', 'tax', 'social', 'contact_persons', 'offerings'].includes(key)
            }


            if (key == 'clients') {
                if (this.getCustomer() && this.getCustomer().customerType && this.getCustomer().customerType.includes('provider')) {
                    return true;
                }
            } else if (key == 'customer_templates') {
                return this.$root.hasAccess('customer_templates', 'view_all')
            } else if (this.customerId) {
                return true;
            }

            return false;
        }
    },
}
</script>